@use "src/css/var/var.scss" as *;
.Button {
    border: unset;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    &_primary {
        background-color: var(--color-primary);
        border-color: var(--color-primary-border);
        color: $white;

        &:hover {
            background-color: var(--color-primary-hover);
            border-color: var(--color-primary-border-hover);
        }

        &:active {
            background-color: var(--color-primary-active);
        }
    }

    &_loading {
        align-items: center;
        display: flex;
        gap: $spacer-xs;
        justify-content: center;
        pointer-events: none;
    }

    &_disabled {
        background-color: $gray-1;
        color: $gray-4;
        pointer-events: none;
    }
}
