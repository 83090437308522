@use "src/css/var/var.scss" as *;
.CatalogLinks {
    &_companyNameTag {
        align-self: center;
        margin-bottom: $spacer-3xl * 2;
    }

    &_reviewIcon {
        align-self: center;
        color: var(--color-primary);
        margin-bottom: $spacer-3xl;
    }

    &_title {
        margin-bottom: $spacer-s;
    }

    &_description {
        font-size: 16px;
        line-height: 24px;
    }

    &_buttons {
        display: flex;
        flex-direction: column;
        margin-top: $spacer-3xl;
        width: 100%;
    }
}
