@use "src/css/var/var.scss" as *;
.CompanyNameTag {
    align-items: center;
    align-self: center;
    background: rgba(23, 23, 23, 0.05);
    border-radius: $spacer-l;
    display: inline-flex;
    max-width: 100%;
    padding: $spacer-s $spacer-l;

    &_icon {
        color: var(--color-primary);
        margin-right: $spacer-s;
        width: 16px;
    }

    &_text {
        font-size: 16px;
        line-height: 24px;
        margin: 0;
    }
}
