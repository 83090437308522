@use "src/css/var/var.scss" as *;
.CatalogLink {
    align-items: center;
    background-color: $white;
    border: 0;
    border-radius: 12px;
    box-shadow:
        0 2px 10px -2px rgba(23, 23, 23, 0.06),
        0 2px 4px -1px rgba(23, 23, 23, 0.12);
    display: flex;
    height: 56px;
    padding: $spacer-l $spacer-xl;
    text-decoration: none;

    &_text {
        flex-grow: 1;
        text-align: center;
    }

    @media (hover: hover) {
        &:hover &_text {
            color: var(--color-primary-text-hover);
        }
    }

    &:active &_text {
        color: var(--color-primary-text-active);
    }

    &:not(:last-child) {
        margin-bottom: $spacer-l;
    }

    &_icon {
        width: 20px;
    }

    &_fakeIcon {
        margin-left: $spacer-s;
        width: 20px;

        @media screen and (max-width: 350px) {
            display: none;
        }
    }
}
