@use "src/css/var/var.scss" as *;
.TypographyText {
    color: $gray-9;
    margin: 0;
    padding: 0;

    &_strong {
        font-weight: 600;
    }

    &_secondary {
        color: $gray-6;
    }

    &_small {
        font-size: 12px;
        line-height: 22px;
    }

    &_medium {
        font-size: 14px;
        line-height: 24px;
    }

    &_large {
        font-size: 16px;
        line-height: 24px;
    }

    &_ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.TypographyTitle {
    color: $gray-9;
    font-weight: 600;

    &_ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &_small {
        font-size: 20px;
        line-height: 28px;
    }

    &_medium {
        font-size: 24px;
        line-height: 32px;
    }

    &_large {
        font-size: 30px;
        line-height: 38px;
    }
}
