/* stylelint-disable scss/dollar-variable-empty-line-before */

$gray-1: #eef2f6;
$gray-2: #e3e8ef;
$gray-3: #cdd5df;
$gray-4: #9da7b4;
$gray-6: #4d596c;
$gray-9: #121926;

$white: #fff;

$amber-50: #edb707;

$danger-50: #e03b47;
